<template>
    <b-card header-tag="header" body-bg-variant="light">
        <template #header>
            <h5 class="mb-0">{{$route.name}}</h5>
        </template>
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
              <template v-for="(m, index) in model">
                <form-group-input :key="index" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
              </template>
              <div>
                <b-button type="submit" variant="primary" class="mt-3"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Simpan</b-button>
                <b-button :href="'#/user/' + $route.params.id" variant="secondary" class="ml-3 mt-3 float-right"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Batal</b-button>
              </div>
            </form>
        </ValidationObserver>
      </b-card>
</template>

<script>
import { saveProcess, setModelValue, scrollToError, objectToOptions } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput.vue'
import User from '@/models/User.js'

const userModel = new User()

export default {
  components: {
    formGroupInput
  },
  watch: {
    '$route.params.id': function (val) {
      if (this.mode === 'update') {
        this.getDetail()
      }
    }
  },
  data () {
    return {
      isLoading: false,
      model: userModel.init()
    }
  },
  mounted () {
    this.model.password.rules = 'min:5'
    this.model.email.type = 'hidden'
    this.model.email.rules = null
    this.model.role.type = 'hidden'
    this.model.role.rules = null
    this.getDetail()
  },
  methods: {
    getOptions (key) {
      return typeof this.model[key].options !== 'undefined' ? objectToOptions(this.model[key].options) : null
    },
    getDetail () {
      this.$http.get(process.env.VUE_APP_API_URL + 'me').then(resp => {
        setModelValue(this.model, resp.data)
        Object.keys(this.model).forEach(key => {
          this.$refs[key][0].setValue(this.model[key].value)
        })
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
      })
    },
    resetForm () {
      this.$nextTick(() => {
        // clearModelValue(this.model)
        Object.keys(this.model).forEach(key => {
          this.$refs[key][0].setValue(null)
        })
        this.$refs.form.reset()
      })
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success || this.isLoading) {
          scrollToError(this.$refs.form)
          return false
        }
        saveProcess(this.model, true).then(model => {
          this.isLoading = true
          this.$http.post(process.env.VUE_APP_API_URL + 'setting', model).then(() => {
            this.isLoading = false
            this.$store.dispatch('notification/success', 'Data akun berhasil diubah.')
          }).catch(error => {
            this.isLoading = false
            this.$store.dispatch('notification/error', error)
          })
        }).catch(err => {
          console.log(err)
        })
      })
    }
  }
}
</script>